import React, {useEffect, useState} from 'react'
import Api from '../server/Api';
import {Endpoint} from '../server/Endpoint';
import { useParams } from 'react-router-dom';
import LayoutShop from '../components/layouts/LayoutShop';
import Swal from 'sweetalert2';

const Product = ({settingLoading, cart, addCounterCart, user}) => {

  const [product, setProduct] = useState({});
  const { psid } = useParams();

  let field = {
    quantity: 1
  }

  const [fields, setFields] = useState(field);
  const changeState = e => {
    // setFields(
    //   {
    //     ...fields,
    //     [e.target.name] : e.target.value
    //   }
    // );

    // addCounterCart({...product, cantidad: e.target.value});
  }

  useEffect(() => {
    let api = new Api();
    (async () => {
        const productsTmp = await api.get(Endpoint.host + Endpoint.products + Endpoint.product + '?psid=' + psid);
        // console.log(productsTmp.data);
        setProduct(productsTmp.data);
    })();
  }, []);

  useEffect(() => {
    settingLoading();
  });
 
  useEffect(() => {
    // settingLoading();
    //addCounterCart(fields.quantity);
  }, [fields]);

  const addToCart = () => {
    setFields({...fields, quantity: window.$('#qty').val()});
    addCounterCart({
        img: window.$('.product-single-image').attr('src').substr(window.$('.product-single-image').attr('src').indexOf('image/') + 6),
        productoSucursalId: product.productoSucursalId, 
        productoPrecioId: 0, 
        cantidad: parseInt(window.$('#qty').val()), 
        type:'add', 
        producto: window.$('.font1.product-title').html(),
        precioVenta: parseFloat(window.$('.product-price').html().substr(window.$('.product-price').html().indexOf('$') + 2).replace(/,/g, ''))
    });

    Swal.fire({
        allowOutsideClick: false,
        position: 'top-end',
        icon: 'success',
        title: 'Agregado al carrito',
        showConfirmButton: false,
        timer: 2000,
    });
  }

  const buyNow = () =>{
    Swal.fire({
        html: `
        <div>Hola</div>
        `,
        width: 800,
        height: 600,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'X',
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
    });
  }

  return (
    <LayoutShop showCover={false} showFilter={false} cart={cart} user={user}>
        <>
        <div className="product-single-container product-single-default">
            {/* <div className="cart-message d-none">
                <strong className="single-cart-notice">“A white Chiar”</strong>
                <span>has been added to your cart.</span>
            </div> */}

            <div className="row">
                <div className="col-lg-5 col-md-6 product-single-gallery">
                    <div className="product-slider-container">
                        <div className="product-single-carousel owl-carousel owl-theme show-nav-hover">
                            <div className="product-item">
                                {
                                    product?.imagen && (<img className="product-single-image" src={Endpoint.storage + "/image/"+product.imagen} data-zoom-image={Endpoint.storage + "/image/"+product.imagen} width="468" height="468" alt="product" />)
                                }
                                
                            </div>
                            {/* <div className="product-item">
                                <img className="product-single-image" src="../assets/images/demoes/demo21/products/zoom/product-1.jpg" data-zoom-image="../assets/images/demoes/demo21/products/zoom/product-1-big.jpg" width="468" height="468" alt="product" />
                            </div>
                            <div className="product-item">
                                <img className="product-single-image" src="../assets/images/demoes/demo21/products/zoom/product-1.jpg" data-zoom-image="../assets/images/demoes/demo21/products/zoom/product-1-big.jpg" width="468" height="468" alt="product" />
                            </div> */}
                        </div>
                        
                        <span className="prod-full-screen">
                            <i className="icon-plus"></i>
                        </span>
                    </div>

                    <div className="prod-thumbnail owl-dots">
                        <div className="owl-dot">
                            
                            {
                                product?.imagen && (<img src={Endpoint.storage + "/image/"+product.imagen} width="110" height="110" alt="product-thumbnail" />)
                            }
                        </div>
                        {/* <div className="owl-dot">
                            <img src="../assets/images/demoes/demo21/products/zoom/product-1.jpg" width="110" height="110" alt="product-thumbnail" />
                        </div>
                        <div className="owl-dot">
                            <img src="../assets/images/demoes/demo21/products/zoom/product-1.jpg" width="110" height="110" alt="product-thumbnail" />
                        </div> */}
                    </div>
                </div>

                {/* <div className="col-lg-5 col-md-6 product-single-gallery">
                    <div className="product-slider-container">
                        <div className="product-single-carousel owl-carousel owl-theme show-nav-hover">
                            <div className="product-item">
                                <img className="product-single-image"
                                    src="../assets/images/demoes/demo21/products/zoom/product-1.jpg"
                                    data-zoom-image="../assets/images/demoes/demo21/products/zoom/product-1-big.jpg"
                                    width="468" height="468" alt="product" />
                            </div>
                            <div className="product-item">
                                <img className="product-single-image"
                                    src="../assets/images/demoes/demo21/products/zoom/product-1.jpg"
                                    data-zoom-image="../assets/images/demoes/demo21/products/zoom/product-1-big.jpg"
                                    width="468" height="468" alt="product" />
                            </div>
                            <div className="product-item">
                                <img className="product-single-image"
                                    src="../assets/images/demoes/demo21/products/zoom/product-1.jpg"
                                    data-zoom-image="../assets/images/demoes/demo21/products/zoom/product-1-big.jpg"
                                    width="468" height="468" alt="product" />
                            </div>
                        </div>
                        
                        <span className="prod-full-screen">
                            <i className="icon-plus"></i>
                        </span>
                    </div>

                    <div className="prod-thumbnail owl-dots">
                        <div className="owl-dot">
                            <img src="../assets/images/demoes/demo21/products/zoom/product-1.jpg" width="110"
                                height="110" alt="product-thumbnail" />
                        </div>
                        <div className="owl-dot">
                            <img src="../assets/images/demoes/demo21/products/zoom/product-1.jpg" width="110"
                                height="110" alt="product-thumbnail" />
                        </div>
                        <div className="owl-dot">
                            <img src="../assets/images/demoes/demo21/products/zoom/product-1.jpg" width="110"
                                height="110" alt="product-thumbnail" />
                        </div>
                    </div>
                </div> */}

                <div className="col-lg-7 col-md-6 product-single-details">
                    <h1 className="font1 product-title">{product?.producto || product.productoEn}</h1>

                    {/* <div className="ratings-container">
                        <div className="product-ratings">
                            <span className="ratings" style={{width:"60%"}}></span>
                            
                            <span className="tooltiptext tooltip-top"></span>
                        </div>

                        <a href="demo21-product.html#" className="rating-link">( 6 Reviews )</a>
                    </div> */}

                    <hr className="short-divider"></hr>

                    <div className="price-box">
                        <span className="product-price"> DOP$ {parseFloat((product.precioSimple != "0.00") ? + product.precioSimple : product.precioMinimo).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
                    </div>

                    <div className="product-desc mb-2">
                        <p>
                            {product.detalle}
                        </p>
                    </div>

                    <ul className="single-info-list" style={{fontSize:"17px"}}>
                        <li>
                            SKU: <strong>{product.codigo}</strong>
                        </li>
                    </ul>

                    <div className="product-action">
                        <div className="product-single-qty">
                            <input className="horizontal-quantity form-control" name='qty' id="qty" onChange={changeState} type="text" value={fields.quantity}/>
                        </div>
                        <button type='button' onClick={addToCart} className='btn btn-dark'>AGREGAR</button>
                        &nbsp;
                        <button type='button' onClick={buyNow} className='btn btn-primary'>COMPRAR</button>

                        {/* <a href="cart.html" className="btn btn-gray view-cart d-none">View cart</a> */}
                    </div>

                    <hr className="divider mb-0 mt-0"></hr>
                    
                </div>
                
            </div>
            
        </div>

        <div className="product-single-tabs">
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="product-tab-desc" data-toggle="tab" href="#product-desc-content" role="tab" aria-controls="product-desc-content" aria-selected="true">Ficha técnica</a>
                </li>

                <li className="nav-item">
                    <a className="nav-link" id="product-tab-size" data-toggle="tab" href="#compatibility" role="tab" aria-controls="compatibility" aria-selected="true">Compatible</a>
                </li>

                <li className="nav-item">
                    <a className="nav-link" id="product-tab-reviews" data-toggle="tab" href="#product-reviews-content" role="tab" aria-controls="product-reviews-content" aria-selected="false">Comentarios</a>
                </li>
            </ul>

            <div className="tab-content">
                <div className="tab-pane fade show active" id="product-desc-content" role="tabpanel" aria-labelledby="product-tab-desc">

                    <table className="table table-striped mt-2">
                        <tbody>
                            <tr>
                                <th>Fabricante</th>
                                <td>{product.fabricante}</td>
                            </tr>

                            <tr>
                                <th>Código</th>
                                <td>{product.codigo}</td>
                            </tr>

                            <tr>
                                <th>Categoria</th>
                                <td>{(product.categoria != "") ? product.categoria : "N/A"}</td>
                            </tr>

                            {
                            ((product?.otrosNombre || product?.otrosNombreEn) && (product?.otrosNombre != "" || product?.otrosNombreEn != "")) ? (
                            
                            <tr>
                                <th>Otros nombre</th>
                                <td>{product?.otrosNombre || product?.otrosNombreEn}</td>
                            </tr>
                            
                            ) : (<></>)
                            }
                            
                            {
                            ((product?.posicion || product?.posicionEn) && (product?.posicion != "" || product?.posicionEn != "")) ? (
                            
                            <tr>
                                <th>Otros nombre</th>
                                <td>{product?.posicion || product?.posicionEn}</td>
                            </tr>
                            
                            ) : (<></>)
                            }

                            <tr>
                                <th>Condición</th>
                                <td>{product.condicion}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="tab-pane fade" id="compatibility" role="tabpanel" aria-labelledby="product-tab-size">
                    <div className="compatibility">
                        <div className="row">

                            <div className="col-md-12">
                                <table className="table table-size">
                                    <thead>
                                        <tr>
                                            <th>Marca & Modelo</th>
                                            <th>Body& Trim</th>
                                            <th>Ingenieria</th>
                                            <th>Año</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (product.compatibilidad?.length > 0) ? (
                                        
                                            
                                            product?.compatibilidad.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.marcaModelo}</td>
                                                    <td>{item.body}</td>
                                                    <td>{item.engineTransmision}</td>
                                                    <td>{item.anio}</td>
                                                </tr>
                                            ))
                                            
                                            ) : (
                                            <tr>
                                            <td>Para todos</td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>

                <div className="tab-pane fade" id="product-reviews-content" role="tabpanel" aria-labelledby="product-tab-reviews">
                    <div className="product-reviews-content">
                        <h3 className="reviews-title">1 review for Men Black Sports Shoes</h3>

                        <div className="comment-list">
                            <div className="comments">
                                <figure className="img-thumbnail">
                                    <img src="../assets/images/blog/author.jpg" alt="author" width="80" height="80" />
                                </figure>

                                <div className="comment-block">
                                    <div className="comment-header">
                                        <div className="comment-arrow"></div>

                                        <div className="ratings-container float-sm-right">
                                            <div className="product-ratings">
                                                <span className="ratings" style={{width:"60%"}}></span>
                                                
                                                <span className="tooltiptext tooltip-top"></span>
                                            </div>
                                            
                                        </div>

                                        <span className="comment-by">
                                            <strong>Joe Doe</strong> – April 12, 2018
                                        </span>
                                    </div>

                                    <div className="comment-content">
                                        <p>Excellent.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="divider"></div>

                        <div className="add-product-review">
                            <h3 className="review-title">Add a review</h3>

                            <form action="demo21-product.html#" className="comment-form m-0">
                                <div className="rating-form">
                                    <label htmlFor="rating">Your rating <span className="required">*</span></label>
                                    <span className="rating-stars">
                                        <a className="star-1" href="demo21-product.html#">1</a>
                                        <a className="star-2" href="demo21-product.html#">2</a>
                                        <a className="star-3" href="demo21-product.html#">3</a>
                                        <a className="star-4" href="demo21-product.html#">4</a>
                                        <a className="star-5" href="demo21-product.html#">5</a>
                                    </span>

                                    <select name="rating" id="rating" required="" style={{display: "none"}}>
                                        <option value="">Rate…</option>
                                        <option value="5">Perfect</option>
                                        <option value="4">Good</option>
                                        <option value="3">Average</option>
                                        <option value="2">Not that bad</option>
                                        <option value="1">Very poor</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Your review <span className="required">*</span></label>
                                    <textarea cols="5" rows="6" className="form-control form-control-sm"></textarea>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-xl-12">
                                        <div className="form-group">
                                            <label>Name <span className="required">*</span></label>
                                            <input type="text" className="form-control form-control-sm" required />
                                        </div>
                                        
                                    </div>

                                    <div className="col-md-6 col-xl-12">
                                        <div className="form-group">
                                            <label>Email <span className="required">*</span></label>
                                            <input type="text" className="form-control form-control-sm" required />
                                        </div>
                                        
                                    </div>

                                    <div className="col-md-12">
                                        <div className=" custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="save-name" />
                                            <label className="custom-control-label mb-0" htmlFor="save-name">Save my
                                                name, email, and website in this browser for the next time I
                                                comment.</label>
                                        </div>
                                    </div>
                                </div>

                                <input type="submit" className="btn btn-primary" value="Submit" />
                            </form>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            
        </div>
        </>
    </LayoutShop>
    
  )
}

export default Product