import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import LayoutSimple from '../../components/layouts/LayoutSimple';

const Address = ({settingLoading, user, cart}) => {
    
    useEffect(() => {
        settingLoading();
    });
    
  return (
    <LayoutSimple divMain={true} user={user} cart={cart}>
        <div className="row row-lg">
            <div className="col-12 col-md-12">
                <center><h4>Dirección</h4></center>
            </div>

            <div className="col-6 col-md-4">
                <div className="feature-box text-center pb-4">
                    <Link to={"orders"}>
                    <i className="fas fa-plus-circle animate__heartBeat"></i>
                    <div className="feature-box-content">
                        <h3>Agregar dirección</h3>
                    </div>
                    </Link>
                </div>
            </div>
            
            <div className="col-3 col-md-3" style={{borderStyle: 'double', marginBottom: '0px', borderRadius: '6px', marginBottom: '10px', padding: '0px', marginRight: '20px'}}>
                <div className="feature-box text-left" style={{marginBottom: '0px'}}>
                    <div className='col-lg-12 text-center' style={{ borderBottomStyle: 'double'}}>Primario</div>
                    <div className='col-lg-12'>Nombre</div>
                    <div className='col-lg-12'>Calle</div>
                    <div className='col-lg-12'>Sector</div>
                    <div className='col-lg-12'>Telefono</div>
                    <div className='col-lg-12'>&nbsp;</div>
                    <div className='col-lg-12'><Link to={""}>Editar</Link> </div>
                </div>
            </div>
            
            <div className="col-3 col-md-3" style={{borderStyle: 'double', marginBottom: '0px', borderRadius: '6px', marginBottom: '10px', padding: '0px', marginRight: '20px'}}>
                <div className="feature-box text-left" style={{marginBottom: '0px'}}>
                    <div className='col-lg-12'>Nombre</div>
                    <div className='col-lg-12'>Calle</div>
                    <div className='col-lg-12'>Sector</div>
                    <div className='col-lg-12'>Telefono</div>
                    <div className='col-lg-12'>&nbsp;</div>
                    <div className='col-lg-12'><Link to={""}>Editar</Link> | <span>Ponerla como predeterminada</span></div>
                </div>
            </div>


        </div>
    </LayoutSimple>
  )
}

export default Address