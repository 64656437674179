import React, {useEffect, useState} from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import LayoutShop from '../components/layouts/LayoutShop';
import { CardProductSearch } from '../components/product/CardProductSearch';
import Api from '../server/Api';
import {Endpoint} from '../server/Endpoint';

const Searcher = ({settingLoading}) => {
    
    const [searchParams] = useSearchParams();
    let filer ={
        search: searchParams.get("k")
    }
    let [search, setSearch] = useState(filer);
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    useEffect(() => {
        let api = new Api();
        (async () => {
            const productsTmp = await api.get(Endpoint.host + Endpoint.products + Endpoint.productsRecient + `?search=${search.search}&offset=0&limit=6`);
            setProducts(productsTmp);
            settingLoading();
        })();
    }, []);

    useEffect(() => {
        let redirect = true;
        if(searchParams.get("k") !== null && searchParams.get("k").replace(' ', '') !== ''){
            redirect = false;
        }
        if(searchParams.get("c") !== null && parseInt(searchParams.get("c")) > 0){
            redirect = false;
        }

        if(redirect){
            navigate('/');
        }
    });
  
  return (
    <LayoutShop>
        <div className="row pb-4">
            {
                (products.length != 0) ? (
                <>
                {
                    products?.map((product) => (
                        <CardProductSearch 
                        key={product.productoSucursalId}
                        product={product}
                        />
                    ))
                }
                </>
                ) : (
                <>
                <h2>Producto no encontrado</h2>
                </>
                )
                
            }
        </div>
    </LayoutShop>
  )
}

export default Searcher