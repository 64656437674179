import React from 'react'

function CardCover() {
  return (
    <div className="home-slide banner" style={{ "backgroundImage": "url('assets/images/demoes/demo21/slider/slide1.jpg')" }}>
        <div className="banner-layer banner-layer-middle">
            <div className="container banner-content">
                <h2 className="font1 font-weight-normal text-uppercase mb-0 appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="200">start the revolution
                </h2>
                <h1 className="font1 font-weight-bold text-uppercase appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="500">
                    drone pro 4
                </h1>
                <h2 className="font1 font-weight-normal text-uppercase mb-3 appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="800">from
                    <strong>$499</strong></h2>
                <a className="btn btn-dark btn-buy appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="1100" href="demo21-shop.html">BUY NOW</a>
            </div>
        </div>
    </div>
  )
}

export default CardCover