import React from 'react'
import {BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import Home from './Home'
import Shop from './Shop'
import Product from './Product';
import NotFound from './NotFound';
import Cart from './Cart';
import Login from './user/Login';
import Searcher from './Searcher';
import Contact from './Contact';
import MapLeaflet from './MapLeaflet';
import Register from './user/Register';
import { Logout } from './user/Logout';
import Account from './account/Account';
import AccountRouter from './account/AccountRouter';

const RouterPage = ({settingLoading, cart, addCounterCart, removeProductCart, processPayment, paymentMethod, user, setUserLogin, getUserLogin})  => {

  return (
    <Router>
        <Routes>
            <Route path='/' element={<Home settingLoading={settingLoading} cart={cart} user={user} getUserLogin={getUserLogin}/>}></Route>
            <Route path='/product' element={<Navigate to='/' />}></Route>
            <Route path='/product/:psid' element={<Product settingLoading={settingLoading} cart={cart} addCounterCart={addCounterCart} user={user} />}></Route>
            <Route path='/shop' element={<Shop settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
            <Route path='/s' element={<Searcher settingLoading={settingLoading} cart={cart} user={user}/>}></Route>

            {/* If user is login not display login */}
            {
              (user == null) ? 
              <Route path='/login' element={<Login settingLoading={settingLoading} cart={cart} user={user} setUserLogin={setUserLogin}/>}></Route> : 
              <Route path='/login' element={<Navigate to='/' />}></Route>
            }
            {/* If user is login not display login */}

            {/*Logout */}
            {
              (user != null) ? 
              <Route path='/logout' element={<Logout settingLoading={settingLoading} cart={cart} user={user} setUserLogin={setUserLogin}/>}></Route> : 
              <Route path='/logout' element={<Navigate to='/' />}></Route>
            }
            {/*Logout */}

            {/* Register when user is not login */}
            {
              (user == null) ? <Route path='/register' element={<Register settingLoading={settingLoading} cart={cart} user={user}/>}></Route> :
              <Route path='/register' element={<Navigate to='/' />}></Route>
            }
            {/* Register when user is not login */}
            
            {/* Register when user is not login */}
            {
                 (user == null) ? <Route path='/account' element={<Navigate to='/' />}></Route> : 
                 <Route path='/account/*' element={<AccountRouter settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
            }
            {/* Register when user is not login */}

            <Route path='/cart' element={<Cart settingLoading={settingLoading} addCounterCart={addCounterCart} cart={cart} removeProductCart={removeProductCart} processPayment={processPayment} paymentMethod={paymentMethod} user={user}/>}></Route>
            <Route path='/contact' element={<Contact settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
            <Route path='/map' element={<MapLeaflet settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
            <Route path='*' element={<NotFound settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
        </Routes>
    </Router>
  )
}

RouterPage.prototype = {
  settingLoading: PropTypes.func.isRequired
}
export default RouterPage