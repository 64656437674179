import React from 'react'
import { Link } from 'react-router-dom'

export const CardProductSearch = ({product}) => {
  return (
    <div className="col-sm-12 col-6 product-default left-details product-list mb-2">
        <figure>
            <Link to={"/product/" + product.productoSucursalId}>
                <img src={"https://partautos.com/image?id=" + product.productoSucursalId + "&tipo=0"} width="250" height="250" alt="product" />
                <img src={"https://partautos.com/image?id=" + product.productoSucursalId + "&tipo=0"} width="250" height="250" alt="product" />
            </Link>
        </figure>
        <div className="product-details">
            <div className="category-list">
                <p>{product.codigo}</p>
            </div>
            <h3 className="product-title"> <Link to={"/product/" + product.productoSucursalId}>{product.producto}</Link>
            </h3>
            <div className="ratings-container">
                <div className="product-ratings">
                    <span className="ratings" style={{width: "100%"}}></span>
                    <span className="tooltiptext tooltip-top"></span>
                </div>
                
            </div>
            
            <p className="product-description">{product.detalle}</p>
            <div className="price-box">
                <span className="product-price">DOP$ {(product.precioSimple !== "0.00" ? product.precioSimple : product.precioMinimo)}</span>
            </div>
            
        </div>
    </div>
  )
}
