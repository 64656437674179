import React, {useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import LayoutLogin from '../../components/layouts/LayoutLogin';
import { validateEmail, alertLoading, alertConfirm, encrypt } from '../../utilities'
import Api from '../../server/Api'
import {Endpoint} from '../../server/Endpoint';

const api = new Api();
const Register = () => {

  const errorDiv = useRef(null);
  const btnEnter = useRef(null);
  const checkboxTerm = useRef(null);
  
  const params = {
    nombre: '',
    apellido: '',
    email: '',
    clave: '',
    clave2: '',
    term: false
  }
  const [form, setForm] = useState(params);
  const changeStatus = (e) => {

    setForm({
      ...form,
      [e.target.name] : e.target.value
    });

    if(window.document.getElementById('l-' + [e.target.name]) != null){
      window.document.getElementById('l-' + [e.target.name]).remove();
    }
  }

  const pressEnter = (e) => {
    
    if(e.keyCode == 13){

      if(e.target.name == "nombre" && form.nombre.replace(/ /g, '') != ''){
        window.document.getElementById("apellido").focus();
      }

      if(e.target.name == "apellido" && form.apellido.replace(/ /g, '') != ''){
        window.document.getElementById("email").focus();
      }

      if(e.target.name == "email" && validateEmail(form.email.replace(/ /g, ''))){
        window.document.getElementById("clave").focus();
      }
      
      if(e.target.name == "clave" && form.clave.replace(/ /g, '').trim().length >= 6){
        window.document.getElementById("clave2").focus();
      }

      if(e.target.name == "clave2" && form.clave2.replace(/ /g, '').length >= 6 && form.clave === form.clave2){
        window.document.getElementById("btn-registrar").focus();
      }
    }
  }

  const register = async () => {

    btnEnter.current.setAttribute('disabled', true);
    let error = "";

    setForm({
      ...form,
      term: checkboxTerm.current.checked
    });

    if(form.nombre.replace(/ /g, '') == ""){
      error += "<li id='l-nombre'>Nombre</li>";
    }
    if(form.apellido.replace(/ /g, '') == ""){
      error += "<li id='l-apellido'>Apellido</li>";
    }
    if(!validateEmail(form.email)){
      error += "<li id='l-email'>Email invalido</li>";
    }
    if(form.clave.replace(/ /g, '').length < 6){
      error += "<li id='l-clave'>La contraseña debe de ser de 6 caracteres minimo</li>";
    }

    if(form.clave.replace(/ /g, '').length >= 6 && form.clave2.replace(/ /g, '').length > 0 && form.clave !== form.clave2){
      error += "<li id='l-clave2'>Debe de repetir la misma contraseña</li>";
    }

    if(checkboxTerm.current.checked == false){
      error += "<li id='l-term'>Debe de aceptar los terminos </li>";
    }
    
    errorDiv.current.innerHTML = error;

    if(error === ''){
      alertLoading();
      
      let registerUser = null;
      if(checkboxTerm.current.checked !== form.term){
        registerUser = await api.post(Endpoint.host + Endpoint.register, {...form, term: checkboxTerm.current.checked});
      }else{
        registerUser = await api.post(Endpoint.host + Endpoint.register, form);
      }
      if(registerUser.error){
        alertConfirm(registerUser.message);
        btnEnter.current.removeAttribute('disabled');
      }else{
        const data = encrypt(JSON.stringify({
          token: registerUser.token,
          user: registerUser.user
        }));
        window.localStorage.setItem('cxlgs', data);
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      }
    }else{
      btnEnter.current.removeAttribute('disabled');
    }
    
  }

  useEffect(() => {
    window.$('body').addClass('loaded');
  });
  
  return (
    <LayoutLogin>
      <div className="page-content">
        <div className="form-v4-content"> 
            <div className="form-left">
              <Link to="/">
                <h2><img src="/assets/images/logo.png" /></h2>
              </Link>
              <p className="text-1" style={{textAlign: "center"}}>Bienvenid@</p>
              <p className="text-2">
                Al mejor lugar donde podras conseguir las piezas que necesitas con solo un click sin moverte de donde estas. 
              </p>
              <p className="text-2">
                Unetenos y deja de estar dando vueltas buscando sin ningún resultado. Deja que busquemos por tí en nuestros almacenes con más de 500 mil productos a nuestra disposición.
              </p>
            </div>
            <form className="form-detail" action="" method="post" id="fm-registrarme">
              <h2 style={{textAlign: "center"}}>REGISTRARME</h2>
              <div ref={errorDiv} style={{textAlign: "center", marginBottom: "25px", color:"#b94848"}} id="error-msj"></div>
              <div className="form-group">
                <div className="form-row form-row-1">
                  <label htmlFor="nombre">Nombre</label>
                  <input type="text" name="nombre" id="nombre" className="input-text required" required="" onKeyUp={pressEnter} onChange={changeStatus} value={form.nombre}/>
                </div>
                <div className="form-row form-row-1">
                  <label htmlFor="apellido">Apellido</label>
                  <input type="text" name="apellido" id="apellido" className="input-text required" required="" onKeyUp={pressEnter} onChange={changeStatus} value={form.apellido} />
                </div>
              </div>
              <div className="form-row">
                <label htmlFor="email">Correo electronico</label>
                <input type="email" name="email" id="email" className="input-text required" onKeyUp={pressEnter} onChange={changeStatus} value={form.email}/>
              </div>
              <div className="form-group">
                <div className="form-row form-row-1 ">
                  <label htmlFor="clave">Contraseña</label>
                  <input type="password" name="clave" id="clave" className="input-text required" required="" onKeyUp={pressEnter} onChange={changeStatus} value={form.clave}/>
                </div>
                <div className="form-row form-row-1">
                  <label htmlFor="clave2">Repetir Contraseña</label>
                  <input type="password" name="clave2" id="clave2" className="input-text required" required="" onKeyUp={pressEnter} onChange={changeStatus} value={form.clave2}/>
                </div>
              </div>
              
              <div className="form-row">
                <div className="form-checkbox">
                  <label className="container-checkbox">
                    <input type="checkbox" ref={checkboxTerm} name='term' value="1" />
                    <span className="checkmark"></span>
                    <p>Acepta los términos y condiciones </p>
                  </label>
                </div>
              </div>

              <div className="form-row">
                <input type="button" onClick={register} ref={btnEnter} name="btn-registrar" id="btn-registrar" className="register" value="CREAR CUENTA" />
              </div>

              <div className="form-row" style={{marginTop: "10px"}}>
                    <p htmlFor="inicialsession"><Link to="/login" style={{color: "blue"}}>Iniciar Sesión</Link></p>
              </div>
            </form>
        </div>
      </div>
    </LayoutLogin>
  )
}

export default Register