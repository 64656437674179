import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
const MapLeaflet = () => {

  const position = [18.4603333,-69.8001633];

  return (
    <MapContainer center={position} zoom={12} scrollWheelZoom={false} style={{width: "100%", height:"500px"}}>
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'/>
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  )
}

export default MapLeaflet