import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
/*
if(window.location.origin.includes('www') === false && process.env.NODE_ENV != "development"){
    window.location.href = 'https://www.' + window.location.host;
}
*/
const root = ReactDOM.createRoot(document.getElementById('main-container'));
root.render(
    <App />
);
