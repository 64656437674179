import React from 'react';
import { Link } from 'react-router-dom';

function MobileContainer({user}) {
  return (
    <div className="mobile-menu-container">
        <div className="mobile-menu-wrapper">
            <span className="mobile-menu-close"><i className="fa fa-times"></i></span>
            <nav className="mobile-nav">
                <ul className="mobile-menu">
                    <li><Link to="/">Inicio</Link></li>
                    {
                        (user == null) ? 
                        <li><Link to="/login">Acceder</Link></li>
                        : 
                        <li>
                            <a>{user.nombre + " " + user.apellido}</a>
                            <ul>
                                <li>
                                    <Link to="/account"><i className='fas fa-user'></i> Mi cuenta</Link>
                                </li>
                                <li>
                                    <Link to="/orde"><i className='fas fa-th-list'></i> Pedidos</Link>
                                </li>
                                <li><Link to="/logout"><i className="fas fa-sign-out-alt"></i>  Cerrar sesión </Link></li>
                            </ul>
                        </li>
                    }
                </ul>
            </nav>

            {/* 
            <form className="search-wrapper mb-2" action="demo21.html#">
                <input type="text" className="form-control mb-0" placeholder="Search..." required />
                <button className="btn icon-search text-white bg-transparent p-0" type="submit"></button>
            </form> 
            */}

        </div>
        
    </div>
  )
}

export default MobileContainer