import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const MenuPrincipal = () => {

    const location = useLocation();
    return (
        <div className="header-center">
            <nav className="main-nav w-100">
                <ul className="menu">
                    <li className={(location.pathname) === "/" ? "active" : ""}>
                        <Link to="/">Inicio</Link>
                    </li>
                    <li className={(location.pathname) === "/shop" ? "active" : ""}>
                        <Link to="/shop">Tienda</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default MenuPrincipal