import React, { useState, useEffect } from 'react';
import RouterPage from './pages/RouterPage';
import Api from './server/Api';
import {Endpoint} from './server/Endpoint';
import Swal from 'sweetalert2';
import { alertConfirm, alertLoading, encrypt, decrypt } from './utilities';

function App() {

  const [user, setUser] = useState(null);
  const [cartItems, setCart] = useState(0);
  const api = new Api();
  const [token, setToken] = useState(null);

  const addCounterCart = async (productIn) => {

    const user = getUserLogin()?.user;
    let carts = 0;

    const product =  {
      producto_sucursal_id: productIn.productoSucursalId,
      producto_precio_id: productIn.productoPrecioId,
      cantidad: productIn.cantidad,
      type: productIn?.type || 'none'
    };

    if(user == null){
      let localCarts = window.localStorage.getItem('pds');
      if(localCarts == null){
        localCarts = {};
        localCarts[`PSI_${productIn.productoSucursalId}`] = {};
        localCarts[`PSI_${productIn.productoSucursalId}`][`PPI_${productIn.productoPrecioId}`] = {...product, producto: productIn.producto, precioVenta: productIn.precioVenta, img: productIn.img};
      }else{
        localCarts = JSON.parse(decrypt(localCarts));
        if(!localCarts?.[`PSI_${productIn.productoSucursalId}`]?.[`PPI_${productIn.productoPrecioId}`]){
          localCarts[`PSI_${productIn.productoSucursalId}`] = {};
          localCarts[`PSI_${productIn.productoSucursalId}`][`PPI_${productIn.productoPrecioId}`] = {...product, producto: productIn.producto, precioVenta: productIn.precioVenta, img: productIn.img};
        }else{
          if(productIn.type == 'inCart'){
            localCarts[`PSI_${productIn.productoSucursalId}`][`PPI_${productIn.productoPrecioId}`].cantidad = product.cantidad;
          }else{
            localCarts[`PSI_${productIn.productoSucursalId}`][`PPI_${productIn.productoPrecioId}`].cantidad = localCarts[`PSI_${productIn.productoSucursalId}`][`PPI_${productIn.productoPrecioId}`].cantidad + product.cantidad;
          }
        }
      }

      const productSucursal = Object.values(localCarts);
      productSucursal.map((ps) => {
          const productPrecio = Object.values(ps);
          productPrecio.map((pc) => {
              carts += pc.cantidad;
          });
      });
      window.localStorage.setItem('pds', encrypt(JSON.stringify(localCarts)));
      setCart(carts);
      return;
    }
    
    product['usuario_id'] = user.id;
    const cart = await api.post(Endpoint.host + Endpoint.cart, product);

    if(cart.error == false && cart.message == 'success'){
      setCart(cart.items);
    }
  }

  const removeProductCart = async (id) => {

    const user = getUserLogin()?.user;

    if(user == null){
      let items = 0;
      let indxKey = 0;
      let indxValue = 0;
      let localCarts = window.localStorage.getItem('pds');
      localCarts = JSON.parse(decrypt(localCarts));
      const productSucursal = Object.values(localCarts);
      let keys = Object.keys(localCarts);
      productSucursal.map((ps) => {
          const productPrecio = Object.values(ps);
          productPrecio.map((pc) => {

              if(id == indxValue){
                delete(localCarts[keys[indxKey]][`PPI_${pc.producto_precio_id}`]);
              }else{
                items += pc.cantidad;
              }

              indxValue++;
          });
          indxKey++;
      });

      window.localStorage.setItem('pds', encrypt(JSON.stringify(localCarts)));
      setCart(items);

      return;
    }
    const cart = await api.delete(Endpoint.host + Endpoint.cart, {
      id,
      usuario_id: user.id
    });

    if(cart.error == false && cart.message == 'ok'){
      setCart(cart.items);
    }
  }

  const settingLoading = () => {
    window.mainJs();
  }

  useEffect(() => {
  }, [cartItems, user]);

  useEffect(() => {
    let api = new Api();
    let items = 0;
    const userTemp = getUserLogin(); // Load User
    (async () => {
      if(userTemp != null && userTemp?.user != null){
        items = await api.get(Endpoint.host + Endpoint.itemsInCart + "?usuario_id=" + userTemp?.user.id, userTemp?.token);
      }else{
        let localCarts = window.localStorage.getItem('pds');
        if(localCarts != null){
          localCarts = JSON.parse(decrypt(localCarts));
          const productSucursal = Object.values(localCarts);
          productSucursal.map((ps) => {
              const productPrecio = Object.values(ps);
              productPrecio.map((pc) => {
                items += pc.cantidad;
              });
          });
        }
      }

      setCart(items);
    })();
  }, []);

  const paymentMethod = () => {

    const user = getUserLogin()?.user;
    if(!user){
      window.location.href = '/login?info=cart';
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      title: 'Método de pago',
      width: 500,
      // height: 600,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      html: `<div class="row col-lg-12"> 
                <div class="col-lg-12">
                  <button type="button" class="btn btn-block btnPayment" style="border-radius: 10px;
                  text-align: -webkit-center;" id="credit-card"><img src="/assets/images/credit-card.png"></button>
                </div>        
                <div class="col-lg-12">
                  &nbsp;
                </div>        
                <div class="col-lg-12">
                  <button type="button" class="btn btn-block btnPayment" id="paypal" style="border-radius: 10px;
                  text-align: -webkit-center;"><img src="/assets/images/paypal.png" height="54px" style="height: 54px;"></button>
                </div>        
             </div>`
    });
    window.$('.swal2-container').find('#credit-card').on('click', () =>{
      processPayment('credit-card');
    });
    window.$('.swal2-container').find('#paypal').on('click', () => {
      processPayment('paypal');
    });
  }

  const processPayment = async (method) => {

    let paymentId = 0;
    alertLoading()

    const resp = {
      error: false,
      message: '',
      i18nKey: ''
    };

    switch(method){
      case 'paypal':
        paymentId = 1;
        break;
      case 'credit-card':
        paymentId = 2;
        break;
    }

    let coupon = ''; // btnApplyCoupon
    if(window.$('#coupon').length == 1 
        && window.$('#btnApplyCoupon').length == 1 
        && typeof window.$('#btnApplyCoupon').attr('disabled') != 'undefined' 
        && window.$('#coupon').val() != ""){
      coupon = window.$('#coupon').val();
    }

    const userTemp = getUserLogin().user; // Load User
    const payment = await api.post(Endpoint.host + Endpoint.payment, {
      userId: userTemp.id,
      paymentMethod: paymentId,
      coupon
    });

    if(payment.error == false && payment.message == 'success'){
      window.location.href = payment.link;
    }else{
      resp.error = true;
      resp.message = payment.message;
      resp.i18nKey = payment.i18nKey;
    }

    if(resp.error){
      alertConfirm(resp.message);
    }

    return resp;
  }

  const getUserLogin = () => {
    let userTmp = window.localStorage.getItem('cxlgs');
    if(userTmp != null){
      try{
        userTmp = JSON.parse(decrypt(userTmp));
        setToken(userTmp?.token);
        setUser(userTmp?.user);
      }catch(e){
        // console.log(e);
        userTmp = null;
      }
    }

    return userTmp;
  }

  const getToken = () => {

    return token;
  }

  const setUserLogin = async (user) => {
    setUser(user);
    if(user == null){
      setToken(null);
      setCart(0);
    }
  }

  return (
    <>
    <RouterPage 
    settingLoading={settingLoading} 
    processPayment={processPayment} 
    cart={cartItems} 
    addCounterCart={addCounterCart} 
    removeProductCart={removeProductCart} 
    paymentMethod={paymentMethod} 
    user={user} 
    setUserLogin={setUserLogin} 
    getUserLogin={getUserLogin}
    />
    </>
  );
}

export default App;
