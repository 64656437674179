import React from 'react'
import { Link } from 'react-router-dom'

const BannerLeft = ({banner}) => {
    
  return (
    <div className='widget'>
        <Link to={"/" + banner.id}>
            <img src={banner.img} alt="product" width="334px" style={{height: "600px"}}/>
        </Link>
    </div>
  )
}

export default BannerLeft