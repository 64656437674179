import React, {useEffect, useState, useRef} from 'react'
import ItemCart from '../components/cart/ItemCart';
import LayoutSimple from '../components/layouts/LayoutSimple';
import Api from '../server/Api';
import { Endpoint } from '../server/Endpoint';
import { renderToStaticMarkup } from "react-dom/server";
import { decrypt } from '../utilities';

const Cart = ({settingLoading, addCounterCart, cart, removeProductCart, paymentMethod, user}) => {

  const [itemsX, setItemsX] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [total, setTotal] = useState(0);
  const [fields, setFields] = useState({coupon: ''});
  const txtCoupon = useRef(null);
  const btnApplyCoupon = useRef(null);
  const trDetailsCoupon = useRef(null);
  const btnProcessPayment = useRef(null);
  const api = new Api();
  const [shippingError, setShippingError] = useState({
	error: false,
	message: '',
	i8nKey: ''
  });

  const changeState = e => {
    setFields(
      {
        ...fields,
        [e.target.name] : e.target.value
      }
    );

	if(btnApplyCoupon.current.getAttribute('disabled') != null){
		btnApplyCoupon.current.removeAttribute('disabled');
	}

	if(trDetailsCoupon.current.style.display != 'none'){
		trDetailsCoupon.current.style.display = 'none';
		trDetailsCoupon.current.innerHTML = '';
	}
  }

  const validateCoupon = async(e) => {

	if(fields.coupon != ''){
		e.target.setAttribute('disabled', true);
		btnApplyCoupon.current.innerHTML = 'Validando...';
		const details = await api.post(Endpoint.host + Endpoint.cart + Endpoint.coupon, {usuario_id: (user?.id || 0), codigo: fields.coupon});
		if(details.error){
			trDetailsCoupon.current.innerHTML = `<td colspan="2" style="text-align: center; vertical-align: middle;"><font color="red">${details.message}</font></td>`;
			btnApplyCoupon.current.removeAttribute('disabled');
			trDetailsCoupon.current.style.display = '';
		}else{
			trDetailsCoupon.current.innerHTML = '';
			const li = [];
			const coupon = details?.details;
			let element = null;
			if(coupon.producto){
				const elementSpan = React.createElement('span', {key: 1, style: {fontSize: "15px", fontWeight: "normal"}}, coupon.producto);
				element = React.createElement('li', {key: 1, style: {fontSize: "15px", fontWeight: "bold"}}, `Producto: `, elementSpan);
				li.push(element);
			}
			if(coupon.categoria){
				element = React.createElement('li', {key: 2, style: {fontSize: "15px", fontWeight: "bold"}}, "Categoria: " + coupon.categoria);
				li.push(element);
			}
			if(coupon.soloEnvio == 1){
				element = React.createElement('li', {key: 3, style: {fontSize: "15px", fontWeight: "bold"}}, "Solo envio");
				li.push(element);
			}
			if(coupon.modo == 1){
				element = React.createElement('li', {key: 4, style: {color: "green", fontSize: "15px", fontWeight: "bold"}}, "- DOP$ " + coupon.monto);
				li.push(element);
			}else{
				element = React.createElement('li', {key: 5, style: {color: "green", fontSize: "15px", fontWeight: "bold"}}, "- " + coupon.monto + " %");
				li.push(element);
			}
			const td = React.createElement('td', {colSpan: "2"}, li);
			const reatToHtml = renderToStaticMarkup(td);
			trDetailsCoupon.current.innerHTML = reatToHtml;
			trDetailsCoupon.current.style.display = '';
		}
		// console.log(details);
		btnApplyCoupon.current.innerHTML = 'Aplicar cupón';
	}
  }

  const calculate = () => {

	let sTotal = 0;
	const dataId = window.document.querySelectorAll('div[data-id]');
	const childCheck = window.document.querySelectorAll('.child-check');
	let product = null;
	let quantity = null;
	for(let i = 0; i < dataId.length; i++){
		
		if(!childCheck[i].checked){
			continue;
		}
		product = itemsX.filter(item => item.id == parseInt(dataId[i].getAttribute('data-id')))?.[0];
		quantity = parseInt(dataId[i].querySelector('.item-product').value);
		if(product){
			sTotal += parseFloat(product.precioVenta) * quantity;
		}
	}

	setSubTotal(sTotal);
	setTotal(sTotal + shipping);
  }

  const changeItem = (element) => {

	let prodTotal = 0;
	const product = itemsX.filter(item => item.id == parseInt(element.target.parentElement.parentElement.getAttribute('data-id')))?.[0]
	
	if(product){
		prodTotal = parseFloat(product.precioVenta) * parseInt(element.target.value);
		element.target.parentElement.parentElement.parentElement.parentElement.querySelector('#t-price').innerHTML = prodTotal.toLocaleString(undefined, { minimumFractionDigits: 2 });
		calculate();
		addCounterCart({productoSucursalId: product.productoSucursalId, productoPrecioId: 0, cantidad: parseInt(element.target.value), type: "inCart"});
	}
  }

  const mainCheck = (event) => {

	window.$('.child-check').prop('checked', event.target.checked);
	calculate();
  }

  const childCheck = (event) =>{

	calculate();

  }

  useEffect(() => {
	(async () => {

		// Get user
		let userTmp = window.localStorage.getItem('cxlgs');
		if(userTmp != null){
			userTmp = JSON.parse(decrypt(userTmp));
			user = userTmp.user;
		}
		if(user == null){
			let localCarts = window.localStorage.getItem('pds');
			if(localCarts != null){
				let items = [];
				let idx = 0;
				localCarts = JSON.parse(decrypt(localCarts));
				const productSucursal = Object.values(localCarts);
				productSucursal.map((ps) => {
					const productPrecio = Object.values(ps);
					productPrecio.map((pc) => {
						
						items.push({
							id: idx,
							cantidadSolicitada: pc.cantidad,
							productoSucursalId: pc.producto_sucursal_id,
							...pc
						});
						idx++;
					});
				});
				setItemsX(items);
			}
			return;
		}
		const itemsTmp = await api.get(Endpoint.host + Endpoint.cart + '?usuario_id=' + (user?.id || 0));

		setItemsX(itemsTmp?.items || []);
		setShipping(itemsTmp?.shipping.cost);
		if(itemsTmp?.shipping.error){
			setShippingError({
				error: true,
				message: itemsTmp?.shipping.message,
				i18nKey: itemsTmp?.shipping.i18nKey
			});
		}
	})();
  }, []);
  
  useEffect(() => {
	window.$('.item-product').off('change');
	window.$('.item-product').change(changeItem);

	// Main check
	window.$('#main-check').off('change');
	window.$('#main-check').change(mainCheck);

	// Child check
	window.$('.child-check').off('change');
	window.$('.child-check').change(childCheck);
	
	calculate();
    settingLoading();
  });
  
  const removeItem = (element) => {
	element = element.target.parentElement.parentElement.parentElement.parentElement.parentElement;
	const id = parseInt(element.querySelector('div[data-id]').getAttribute('data-id'));
	const product = itemsX.filter(item => item.id === id)?.[0];
	if(product){
		window.$(element).toggle('slow');
		removeProductCart(id);
	}
  }

  const buy = () => {
	btnProcessPayment.current.setAttribute('disabled', true);
	paymentMethod();
	setTimeout(() => {
		btnProcessPayment.current.removeAttribute('disabled');
	}, 2000)

  }
  return (
    <LayoutSimple divMain={true} cart={cart} user={user}>

		{
			(cart == 0) ? 
			<>
			<span>Aún no tienes articulos en el carrito.</span>
			</> 
			:
			<>
				<ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
					<li className="active">
						<a href="cart.html">Carrito</a>
					</li>
				</ul>

				<div className="row">
					<div className="col-lg-8">
						<div className="cart-table-container">
							<table className="table table-cart">
								<thead>
									<tr>
										<th className="thumbnail-col center-contain-div">
											<label className="container-checkbox">
												<input type="checkbox" id="main-check"/>
												<span className="checkmark"></span>
											</label>
											Marcar todo
										</th>
										<th className="product-col">Producto</th>
										<th className="price-col">Precio</th>
										<th className="qty-col">Cantidad</th>
										<th className="text-right">Subtotal</th>
									</tr>
								</thead>
								<tbody>
									{
										itemsX?.map((item) => (
											<ItemCart 
											key={item.id}
											details={item}
											removeItem={removeItem}
											/>
										))
									}
								</tbody>
							</table>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="cart-summary">
							<div className="input-group">
								<input type="text" name='coupon' id="coupon" className="form-control coupon" placeholder="Cupón" value={fields.coupon} onChange={changeState} ref={txtCoupon}/>
								<div className="input-group-append">
									<button className="btn btn-default" id="btnApplyCoupon" type="button" style={{height: "45px", color:"#08C", cursor:"pointer"}} ref={btnApplyCoupon} onClick={validateCoupon}>Aplicar Cupón</button>
								</div>
							</div>
							<table className="table table-totals">
								<tbody>
									<tr ref={trDetailsCoupon} style={{display: 'none'}} id="tr-coupon">
									</tr>
									<tr>
										<td>Subtotal</td>
										<td id="s-total">DOP$ {subTotal.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
									</tr>
									<tr>
										<td>Envio</td>
										<td>DOP$ {shipping.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
									</tr>
								</tbody>

								<tfoot>
									<tr>
										<td>Total</td>
										<td>DOP$ {total.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
									</tr>
								</tfoot>
							</table>

							<div className="checkout-methods">
								<button type='button' ref={btnProcessPayment} className="btn btn-block btn-blue" onClick={buy}>Proceder a pagar</button>
							</div>
						</div>
					</div>
				</div>
			</>
		}
    </LayoutSimple>
  )
}

export default Cart;