import React, {useEffect, useState, useRef} from 'react'
import { Link } from 'react-router-dom'
import LayoutLogin from '../../components/layouts/LayoutLogin'
import { validateEmail, alertConfirm, alertLoading, encrypt, decrypt } from '../../utilities'
import Api from '../../server/Api'
import {Endpoint} from '../../server/Endpoint';
// import Swal from 'sweetalert2';

const api = new Api();

const Login = () => {

  const initText = {enter: "Entrar", register: "Crear nueva cuenta"}
  const [textDefault, setTextDefault] = useState(initText);
  const errorDiv = useRef(null);
  const inputEnter = useRef(null);
  const [params, setParams] = useState({
    email: '',
    password: ''
  });

  const changeState = (e) =>{
    setParams(
      {
        ...params,
        [e.target.name] : e.target.value
      }
    );

    if(e.target.name === "email" && window.document.getElementById('errorEmail') != null){
      window.document.getElementById('errorEmail').remove();
    }

    if(e.target.name === "password" && window.document.getElementById('errorPassword') != null){
      window.document.getElementById('errorPassword').remove();
    }
  }

  useEffect(() => {
    window.$('body').addClass('loaded');
  });

  const enter = async () => {
    inputEnter.current.setAttribute('disabled', true);
    let error = '';
    if(!validateEmail(params.email.replace(/ /g, ''))){
      error += '<li id="errorEmail">Email invalido</li>';
    }

    if(params.password.replace(/ /g, '') === ''){
      error += '<li id="errorPassword">Clave requerido</li>';
    }

    if(error == "" && params.password.trim().length < 6){
      error += '<li id="errorPassword">La clave debe ser de 6 caracteres minimo.</li>';
    }
    errorDiv.current.innerHTML = error;

    if(error.length == 0){
      alertLoading();
      const login = await api.post(Endpoint.host + Endpoint.login, params);
      if(login.error){
        alertConfirm(login.message);
        inputEnter.current.removeAttribute('disabled');
      }else{
        const data = encrypt(JSON.stringify({
          token: login.token,
          user: login.user
        }));
        window.localStorage.setItem('cxlgs', data);

        // Move local cart to server
        let localCarts = window.localStorage.getItem('pds');
        if(localCarts != null){
          localCarts = JSON.parse(decrypt(localCarts));
          const productSucursal = Object.values(localCarts);
          await Promise.all(
            productSucursal.map(async(ps) => {
              const productPrecio = Object.values(ps);
              return Promise.all(
                productPrecio.map(async (pc) => {
                  
                  const product =  {
                    producto_sucursal_id: pc.producto_sucursal_id,
                    producto_precio_id: pc.producto_precio_id,
                    cantidad: pc.cantidad,
                    type: pc.type,
                    usuario_id: login.user.id
                  };
                  return await api.post(Endpoint.host + Endpoint.cart, product);
                })
              );
            })
          );

          window.localStorage.removeItem('pds');

        }
        // End move local cart to server

        setTimeout(() => {

          const urlParams = new URLSearchParams(window.location.search);
          if(urlParams.get('info') == 'cart'){

            window.location.href = '/cart';
            return;
          }
          window.location.href = '/';
        }, 2000);
      }
    }else{
      inputEnter.current.removeAttribute('disabled');
    }
  }

  const pressEnter = (e) => {
    
    if(e.keyCode == 13){
      if(e.target.name == "email" && validateEmail(params.email.replace(/ /g, ''))){
        window.document.getElementById("password").focus();
      }
      
      if(e.target.name == "password" && params.password.replace(/ /g, '').trim().length >= 6){
        window.document.getElementById("btn-entrar").focus();
        inputEnter.current.click();
      }
    }
  }
  
  return (
    <LayoutLogin>
        <div className="page-content">
					<div className='form-v4-content' style={{width:"400px"}}>
            <form  className="form-detail" action="" method="post" id="fm-registrarme">
            <Link to="/">
              <p style={{textAlign: "center"}}>
                <img src="/assets/images/logo.png" />
              </p> 
            </Link>
              <h2 style={{textAlign: "center"}}>ACCEDER</h2>

              <div ref={errorDiv} style={{textAlign: "center", marginBottom: "25px", color:"#b94848"}} id="error-msj"></div>
              
              <div  className="form-row">
                <label htmlFor="email">Correo electronico</label>
                <input type="email" name="email" id="email"  className="input-text required" onChange={changeState} onKeyUp={pressEnter}/>
              </div>
                
              <div  className="form-row">
                <label htmlFor="clave">Contraseña</label>
                <input type="password" name="password" id="password"  className="input-text required" onChange={changeState} onKeyUp={pressEnter}/>
              </div>

              <div  className="form-row">
                    <p htmlFor="password"><a href="olvide_clave" style={{color: "blue"}}>Olvidé mi contraseña</a></p>
              </div>

              <div className="form-row" style={{marginBottom: "10px"}}>
                <input ref={inputEnter} onClick={enter} type="button" name="register" id="btn-entrar" className="register" value={textDefault.enter} />
              </div>

              <div  className="form-row">
                <Link to="/register"><input type="button" style={{cursor: "pointer", fontWeight:"bold"}}  value={textDefault.register} /></Link>
              </div>

            </form>

          </div>
				</div>
    </LayoutLogin>
  )
}

export default Login