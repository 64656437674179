export default class Api {

    headersOption = null;
    constructor(){
        this.headersOption = {
            headers: {
                'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibm9tYnJlIjoiUHJ1ZWJhIiwiYXBlbGxpZG8iOiJEZW1vIiwidGlwb1VzdWFyaW9JZCI6MSwiZW1haWwiOiJlemVxdWllbGRwY0BnbWFpbC5jb20iLCJjcmVhdGVkQXQiOiIyMDIyLTA4LTE0VDAwOjE1OjE3LjA0MVoiLCJkb21haW4iOiJwYXJ0YXV0b3MuY29tIiwiaWF0IjoxNjYwNDM2MTE3LCJleHAiOjE2OTE5NzIxMTd9.vfCQJbJOiE0gFRO6Y8L44nzxhqFpDvGo8kGm9y_T194'
            }
        }
    }

    get = async (url, token2 = null) =>{
        if(token2 != null){
            this.headersOption.headers['tusx'] = token2;
        }
        const request = await fetch(url, this.headersOption);
        return await request.json();
    }
    
    post = async (url, params, token2 = null) =>{

        this.headersOption['method'] = 'POST';
        this.headersOption.headers['Content-Type'] = 'application/json';
        this.headersOption['body'] = JSON.stringify(params);
        this.headersOption['redirect'] = 'follow';
        if(token2 != null){
            this.headersOption.headers['tusx'] = token2;
        }

        const request = await fetch(url, this.headersOption);
        return await request.json();
    }

    delete = async (url, params, token2 = null) => {

        this.headersOption['method'] = 'DELETE';
        this.headersOption.headers['Content-Type'] = 'application/json';
        this.headersOption['body'] = JSON.stringify(params);
        this.headersOption['redirect'] = 'follow';
        if(token2 != null){
            this.headersOption.headers['tusx'] = token2;
        }

        const request = await fetch(url, this.headersOption);
        return await request.json(); 
    }
}