import React from 'react';
import { Link } from 'react-router-dom';
import MenuPrincipal from '../menu/MenuPrincipal';
import CardProductFlex from '../product/CardProductFlex'
import FormHeaderSearch from './FormHeaderSearch';
import SubMenuHeader from './SubMenuHeader';

const Header = props => {
    
    return (
        <>
        <header className="header box-shadow">
            <div className="header-top" style={{background: "#01abec"}}>
                <div className="container">
                    <SubMenuHeader user={props?.user} />
                </div>
            </div>
            
            <div className="header-middle sticky-header" data-sticky-options="{'mobile': true}">
                <div className="container">
                    <div className="header-left">
                        <button className="mobile-menu-toggler" type="button">
                            <i className="fas fa-bars"></i>
                        </button>
                        <Link to="/" className="logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="PartAutos" width="111" height="44" style={{background: "#ffffff", "borderRadius": "31px", padding: "2px"}}/>
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="PartAutos" width="111" height="44" className="sticky-logo"/>
                        </Link>
                    </div>

                    <div className="header-center">
                        <div className="header-icon header-search header-search-inline header-search-category w-lg-max text-right mt-0">
                            {/* <Link to="/" className="search-toggle" role="button"><i className="icon-search-3"></i></Link> */}
                            <FormHeaderSearch />
                        </div>
                        
                    </div>

                    <div className="header-right ml-0 ml-lg-auto">
                        <div className="dropdown cart-dropdown">
                            <Link to="/cart" title="Cart">
                                <i className="minicart-icon"></i>
                                <span className="cart-count badge-circle">{props?.cart || 0}</span>
                            </Link>

                            <div className="cart-overlay"></div>

                            <div className="dropdown-menu mobile-cart">
                                <a href="#" title="Close (Esc)" className="btn-close">×</a>

                                <div className="dropdownmenu-wrapper custom-scrollbar">
                                    <div className="dropdown-cart-header">Shopping Cart</div>
                                    
                                    <div className="dropdown-cart-products">

                                        <CardProductFlex/>
                                        
                                    </div>

                                    <div className="dropdown-cart-total">
                                        <span>SUBTOTAL:</span>
                                        <span className="cart-total-price float-right">$0.00</span>
                                    </div>

                                    <div className="dropdown-cart-action">
                                        <a href="cart.html" className="btn btn-gray btn-block view-cart">View Cart</a>
                                        <a href="checkout.html" className="btn btn-dark btn-block">Checkout</a>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* Second search */}
            <div className="header-bottom sticky-header" data-sticky-options="{'mobile': false}">
                <div className="container">
                    <div className="header-left">
                        <a href="demo21.html" className="logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="PartAutos"/>
                        </a>
                    </div>
                    <MenuPrincipal />
                    <div className="header-right pr-0">
                        <div className="header-icon header-search header-search-popup header-search-category w-lg-max text-right">
                            {/* <a href="#" className="search-toggle" role="button"><i className="icon-search-3"></i></a> */}
                            <FormHeaderSearch />
                        </div>

                        {/* Cart flex */}
                        <div className="dropdown cart-dropdown">
                            <Link to="/cart" title="Cart">
                                <i className="minicart-icon"></i>
                                <span className="cart-count badge-circle">{props?.cart || 0}</span>
                            </Link>

                            <div className="cart-overlay"></div>

                            <div className="dropdown-menu mobile-cart">
                                <a href="#" title="Close (Esc)" className="btn-close">×</a>

                                <div className="dropdownmenu-wrapper custom-scrollbar">
                                    <div className="dropdown-cart-header">Shopping Cart</div>
                                    

                                    <div className="dropdown-cart-products">

                                        <CardProductFlex/>
                                        
                                    </div>
                                    

                                    <div className="dropdown-cart-total">
                                        <span>SUBTOTAL:</span>

                                        <span className="cart-total-price float-right">$0.00</span>
                                    </div>
                                    

                                    <div className="dropdown-cart-action">
                                        <a href="cart.html" className="btn btn-gray btn-block view-cart">View Cart</a>
                                        <a href="checkout.html" className="btn btn-dark btn-block">Checkout</a>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}

export default Header