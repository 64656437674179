import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Account from './Account';
import Address from './Address';
import Orders from './Orders';
import Details from './Details';

const AccountRouter = ({settingLoading, cart, user}) => {
  return (
    <Routes>
        <Route path='' element={<Account settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
        <Route path='address' element={<Address settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
        <Route path='orders' element={<Orders settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
        <Route path='details' element={<Details settingLoading={settingLoading} cart={cart} user={user}/>}></Route>
    </Routes>
  )
}

export default AccountRouter