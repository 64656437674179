import React from 'react'
import {Endpoint} from '../../server/Endpoint';
import { Link } from 'react-router-dom';

const ItemCart = ({details, removeItem}) => {

  return (
    <tr className="product-row">
      <td>
        <div className="col-lg-12 row" style={{padding: "0px"}}>
          <div className="col-lg-2 center-contain-div">
          <label className="container-checkbox">
            <input type="checkbox" className='child-check' defaultChecked />
            <span className="checkmark"></span>
          </label>
          </div>
          <div className="col-lg-10">
          <figure className="product-image-container">
            <Link to={"/product/" + details.productoSucursalId} className="product-image">
              <img src={Endpoint.storage + "/image/" + details.img} alt="product" />
            </Link>

            <a href={void(0)} onClick={removeItem} className="btn-remove icon-cancel" style={{cursor: 'pointer'}} title="Remove Product"></a>
          </figure>
          </div>
        </div>
      </td>
      <td className="product-col">
        <h5 className="product-title">
          <Link to={"/product/" + details.productoSucursalId}>{details?.producto || details.productoEn}</Link>
        </h5>
      </td>
      <td>DOP$ {parseFloat(details.precioVenta).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
      <td>
        <div className="product-single-qty" data-id={details.id}>
          <input readOnly="readonly" className="horizontal-quantity form-control item-product" type="text" defaultValue={details.cantidadSolicitada}/>
        </div>
      </td>
      <td className="text-right"><span className="subtotal-price">DOP$ <span id="t-price">{parseFloat(parseFloat(details.precioVenta) * parseInt(details.cantidadSolicitada)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span></span></td>
    </tr>
  )
}

export default ItemCart