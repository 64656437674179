import React, { useEffect } from 'react';

export const Logout = ({setUserLogin}) => {
    
    useEffect(() => {
        const user = window.localStorage.getItem('cxlgs');
        if(user != null){
            window.localStorage.removeItem('cxlgs');
            setUserLogin(null);
        }
    }, []);
  return (
    <></>
  )
}
