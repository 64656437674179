import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../header/Header';
import HeaderSimple from '../header/HeaderSimple';
import NavBasic from '../NavBasic';
import Footer from './Footer';
import MobileContainer from './MobileContainer';

const LayoutSimple = props => {

    const divMain = props?.divMain || false;

    let insideContainer = null;
    let outSideContainer = null;
    if(props.children?.length > 1 && divMain){
        outSideContainer = props.children[0];
        insideContainer = props.children[1];
    }else{
        insideContainer = props.children;
    }
    return (
        <>
        <div className="page-wrapper">
            <HeaderSimple cart={props?.cart || 0} user={props?.user}/>
            <main className="main">
                
                {
                    divMain ? (
                    <>

                        {
                            (outSideContainer != null) ? (outSideContainer) : (<></>)
                        }
                        
                        <nav style={{color:"#000", borderTop:"1px solid #dfdfdf", marginBottom:"3.5rem"}}></nav>
                        <div className="container">
                            {insideContainer}
                        </div>
                    </>
                    ) : (
                    <>
                        {insideContainer}
                    </>
                    )
                }
            </main>
            
            <Footer/>
        </div>

        <div className="mobile-menu-overlay"></div>
        <MobileContainer user={props?.user} />
        <NavBasic />

        <Link id="scroll-top" to="/" title="Top" role="button"><i className="icon-angle-up"></i></Link>
        </>
    )
}

export default LayoutSimple;