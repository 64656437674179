 const Endpoint = {
    host: (process.env.NODE_ENV == "development") ? 'http://localhost:3400' : 'https://api.partautos.com',
    storage: 'https://store.partautos.com',
    products: '/api/products',
    cart: '/api/cart',
    coupon: '/coupon',
    itemsInCart: '/api/cart/items',
    productsRecient: '/recent-products',
    product: '/prod',
    payment: '/api/payment',
    login: '/api/login',
    register: '/api/login/register'
}

export {
    Endpoint
}