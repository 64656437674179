import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import LayoutSimple from '../../components/layouts/LayoutSimple';

const Account = ({settingLoading, user, cart}) => {

  useEffect(() => {
    settingLoading();
  });

  return (
    <LayoutSimple divMain={true} user={user} cart={cart}>
      <div className="row row-lg">
        
        <div className="col-6 col-md-4">
          <div className="feature-box text-center pb-4">
            <Link to={"orders"}>
              <i className="sicon-social-dropbox animate__heartBeat"></i>
              <div className="feature-box-content">
                <h3>ORDERS</h3>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6 col-md-4">
          <div className="feature-box text-center pb-4">
            <Link to={"address"}>
              <i className="sicon-location-pin animate__heartBeat"></i>
              <div className="feature-box-content">
                <h3>ADDRESSES</h3>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6 col-md-4">
          <div className="feature-box text-center pb-4">
            <Link to={"details"}>
              <i className="icon-user-2 animate__heartBeat"></i>
              <div className="feature-box-content p-0">
                <h3>ACCOUNT DETAILS</h3>
              </div>
            </Link>
          </div>
        </div>

        {/* <div className="col-6 col-md-4">
          <div className="feature-box text-center pb-4">
            <a href="wishlist.html"><i className="sicon-heart"></i></a>
            <div className="feature-box-content">
              <h3>WISHLIST</h3>
            </div>
          </div>
        </div> */}

      </div>
    </LayoutSimple>
    
  )
}

export default Account