import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../header/Header';
import HeaderSimple from '../header/HeaderSimple';
import NavBasic from '../NavBasic';
import Footer from './Footer';
import MobileContainer from './MobileContainer';

const LayoutLogin = props => {

    return (
        <>
        {props.children}
        </>
    )
}

export default LayoutLogin;