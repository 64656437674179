import React, { useEffect } from 'react'
import LayoutSimple from '../components/layouts/LayoutSimple'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const Contact = ({settingLoading}) => {

    const position = [18.4659013,-69.8061761];
    useEffect(() => {
        settingLoading();
    });


    return (
        <LayoutSimple>
            <nav style={{color:"#000", borderTop:"1px solid #dfdfdf", marginBottom:"3.5rem"}}></nav>
            <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap" style={{height: "25px"}}><li className="active"><a href="cart.html">Contacto</a></li></ul>
            <MapContainer 
                center={position} 
                zoom={16} 
                scrollWheelZoom={false} 
                dragging={false}
                doubleClickZoom={false}
                attributionControl={false}
                zoomControl={false}
                style={{width: "100%", height:"500px"}}>
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
                <Marker position={position} draggable={false}/>
            </MapContainer>
            <div className="container contact-us-container">
            <div className="mb-8"></div>
				<div className="contact-info">
					<div className="row">
						<div className="col-sm-6 col-lg-3">
							<div className="feature-box text-center">
								<i className="sicon-location-pin"></i>
								<div className="feature-box-content">
									<h3>Dirección</h3>
									<h5>Calle Antonio Guzman Fernandez #3 Los Frailes II, Santo Domingo Este. DO</h5>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-3">
							<div className="feature-box text-center">
								<i className="fa fa-mobile-alt"></i>
								<div className="feature-box-content">
									<h3>Teléfono</h3>
									<h5><a href="tel:18092395596">1 (809) 239-5596</a></h5>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-3">
							<div className="feature-box text-center">
								<i className="far fa-envelope"></i>
								<div className="feature-box-content">
									<h3>E-mail</h3>
									<h5><a href='mailto:contacto@partautos.com'>contacto@partautos.com</a></h5>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-3">
							<div className="feature-box text-center">
								<i className="far fa-calendar-alt"></i>
								<div className="feature-box-content">
									<h3>Días/Horas</h3>
									<h5>Online 24/7</h5>
									<h5>Delivery: Lun - Dom / 8:00 am - 6:00 pm</h5>
                                    
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-6">
						<h2 className="mt-6 mb-2">Formulario de contacto</h2>

						<form className="mb-0" action="contact.html#">
							<div className="form-group">
								<label className="mb-1" htmlFor="contact-name">Nombre y Apellido
									<span className="required">*</span></label>
								<input type="text" className="form-control" id="contact-name" name="contact-name"
									required />
							</div>

							<div className="form-group">
								<label className="mb-1" htmlFor="contact-email">E-mail
									<span className="required">*</span></label>
								<input type="email" className="form-control" id="contact-email" name="contact-email" required />
							</div>

							<div className="form-group">
								<label className="mb-1" htmlFor="contact-message">Mensaje
									<span className="required">*</span></label>
								<textarea cols="30" rows="1" id="contact-message" className="form-control"
									name="contact-message" required></textarea>
							</div>

							<div className="form-footer mb-0">
								<button type="submit" className="btn btn-dark font-weight-normal">
									Enviar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
            <div className="mb-8"></div>
        </LayoutSimple>
    )
}

export default Contact