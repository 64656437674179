import React, {useEffect, useState} from 'react'
import LayoutSimple from '../components/layouts/LayoutSimple';

const NotFound = ({settingLoading, user, cart}) => {

  useEffect(() => {
    settingLoading();
  });

  return (
    <LayoutSimple divMain={true} user={user} cart={cart}>
      <>
      <center><h3>Not found page</h3></center>
      </>
    </LayoutSimple>
    
  )
}

export default NotFound