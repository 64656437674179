import Swal from 'sweetalert2';
const CryptoJS = require("crypto-js");

const validateEmail = (email) => {
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
}

const alertConfirm = (message) => {

    Swal.fire({
        icon: 'error',
        html: `<font size="5px">${message}</font>`,
        allowOutsideClick: false,
        showConfirmButton: true,
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
        }
    });
    window.$('.swal2-confirm.swal2-styled').attr('style', 'display: inline-block; background-color: #01abec; font-size: 16px;');
}

const alertLoading = () => {

    Swal.fire({
        title: `<font size="5px">Por favor espere...</font>`,
        allowOutsideClick: false,
        imageUrl: '/assets/images/loading.gif',
        html: '<div id="divLoading"></div>',
        imageHeight: 120,
        showConfirmButton: false,
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
        }
    });
}


const encrypt = (data) => {
    return CryptoJS.AES.encrypt(data, '$2a$10$Awrj/ezVMe4aAi4oUvCYx.zWSw2F0hU.L41IOATWFjR6XI1DvNLXW').toString();
}

// Decrypting text
const decrypt = (data) => {

    const bytes = CryptoJS.AES.decrypt(data, '$2a$10$Awrj/ezVMe4aAi4oUvCYx.zWSw2F0hU.L41IOATWFjR6XI1DvNLXW');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData;
}

export {
    validateEmail, alertConfirm, alertLoading, encrypt, decrypt
}