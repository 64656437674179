import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../header/Header';
import NavBasic from '../NavBasic';
import Footer from './Footer';
import MobileContainer from './MobileContainer';

const Layout = props => {

    const divMain = props?.divMain || false;
    return (
        <>
        <div className="page-wrapper">
            <Header cart={props?.cart || 0} user={props?.user}/>
            <main className="main">
                
                {
                    divMain ? (
                    <>
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    </nav>
                    <div className="container">
                        {props.children}
                    </div>
                    </>
                    ) : (
                    <>
                        {props.children}
                    </>
                    )
                }
            </main>

            <Footer />
        </div>
        
        <div className="mobile-menu-overlay"></div>
        <MobileContainer user={props?.user} />
        <NavBasic />

        <Link id="scroll-top" to="/" title="Top" role="button"><i className="icon-angle-up"></i></Link>
        </>
    )
}

export default Layout;