import React from 'react'
import { Link } from 'react-router-dom';

const SubMenuHeader = ({user}) => {
  return (
    <div className="header-right ml-0 ml-lg-auto" style={{color: "#ffffff", height: "25px"}}>
        <nav className="main-nav w-100">
            <ul className="menu">
                <li>
                    <Link to="/cart" style={{ "fontWeight": "bold", color: "#ffffff"}}>Carrito</Link>
                </li>
                {
                (user == null) ? <li><Link to="/login" style={{ "fontWeight": "bold", color: "#ffffff"}}>Acceder</Link></li> :
                <li>
                    <a style={{ "fontWeight": "bold", color: "#ffffff", cursor:"pointer"}}> {user.nombre + " " + user.apellido} </a>
                    <ul>
                        <li>
                            <Link to="/account" style={{ "fontWeight": "bold"}}><i className='fas fa-user'></i> Mi cuenta</Link>
                        </li>
                        <li>
                            <Link to="/account/orders" style={{ "fontWeight": "bold"}}><i className='fas fa-th-list'></i> Pedidos</Link>
                        </li>
                        <li><Link to="/logout" style={{"fontWeight": "bold", }}><i className="fas fa-sign-out-alt"></i>  Cerrar sesión </Link></li>
                    </ul>
                </li>
                }
            </ul>
        </nav>
    </div>
  )
}

export default SubMenuHeader