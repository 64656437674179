import React, { useState } from 'react'
import BannerLeft from '../banner/BannerLeft';
import LayoutSimple from './LayoutSimple'
import { Button, Modal } from 'react-bootstrap';

const LayoutShop = props => {

    const showCover = (props.showCover != null) ? props.showCover : true;
    const showFilter = (props.showFilter != null) ? props.showFilter : true;
    const [banners, setBanners] = useState([
        {
            id: 1,
            img: "https://sp-ao.shortpixel.ai/client/to_auto,q_lossless,ret_img,w_300,h_600/https://www.marketingterms.com/wp-content/uploads/2022/01/2022-conferences-vertical.jpg"
        }
    ])

    return (
        <LayoutSimple divMain={true} cart={props?.cart || 0} user={props?.user}>
        <>
        {
            (showCover) ? (
            <>
            <div className="category-banner-container">
                <div className="category-banner"
                style={{backgroundImage: "url('../../assets/images/demoes/demo21/banners/shop-banner.jpg')", backgroundColor: "#f4f4f4"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex justify-content-md-center">
                                <div>
                                    <h4 className="font1 font-weight-normal ls-n-10 text-uppercase m-b-2">start the
                                        revolution</h4>
                                    <h2 className="font1 font-weight-bold text-uppercase">
                                        drone pro 4
                                    </h2>
                                    <h3 className="font1 font-weight-normal ls-n-10 line-height-1 text-uppercase mb-0 ml-0">
                                        from <strong>$499</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            ) : (<></>)
        }
        
        </>
        <>
        <div className="row">
            <div className="col-lg-9 main-content">

                {
                    (showFilter) ? (
                    <>
                    <nav className="toolbox sticky-header horizontal-filter filter-sorts" data-sticky-options="{'mobile': true}">
                        <div className="sidebar-overlay d-lg-none"></div>
                        <a href="category-horizontal-filter2.html#" className="sidebar-toggle border-0"><svg data-name="Layer 3" id="Layer_3"
                viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <line x1="15" x2="26" y1="9" y2="9" className="cls-1"></line>
                            <line x1="6" x2="9" y1="9" y2="9" className="cls-1"></line>
                            <line x1="23" x2="26" y1="16" y2="16" className="cls-1"></line>
                            <line x1="6" x2="17" y1="16" y2="16" className="cls-1"></line>
                            <line x1="17" x2="26" y1="23" y2="23" className="cls-1"></line>
                            <line x1="6" x2="11" y1="23" y2="23" className="cls-1"></line>
                            <path d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z"
                            className="cls-2"></path>
                            <path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2"></path>
                            <path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3"></path>
                            <path d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z"
                            className="cls-2"></path>
                        </svg>
                        <span>Filter</span>
                        </a>

                        <aside className="toolbox-left sidebar-shop mobile-sidebar">
                            <div className="toolbox-item toolbox-sort select-custom">
                                <a className="sort-menu-trigger" href="category-horizontal-filter2.html#">Size</a>
                                <ul className="sort-list">
                                    <li><a href="category-horizontal-filter2.html#">Extra Large</a></li>
                                    <li><a href="category-horizontal-filter2.html#">Large</a></li>
                                    <li><a href="category-horizontal-filter2.html#">Medium</a></li>
                                    <li><a href="category-horizontal-filter2.html#">Small</a></li>
                                </ul>
                            </div>

                            <div className="toolbox-item toolbox-sort select-custom">
                            <a className="sort-menu-trigger" href="category-horizontal-filter2.html#">Color</a>
                            <ul className="sort-list">
                                <li><a href="category-horizontal-filter2.html#">Black</a></li>
                                <li><a href="category-horizontal-filter2.html#">Blue</a></li>
                                <li><a href="category-horizontal-filter2.html#">Brown</a></li>
                                <li><a href="category-horizontal-filter2.html#">Green</a></li>
                                <li><a href="category-horizontal-filter2.html#">Indigo</a></li>
                                <li><a href="category-horizontal-filter2.html#">Light Blue</a></li>
                                <li><a href="category-horizontal-filter2.html#">Red</a></li>
                                <li><a href="category-horizontal-filter2.html#">Yellow</a></li>
                            </ul>
                            </div>
                            

                            <div className="toolbox-item toolbox-sort price-sort select-custom">
                                <a className="sort-menu-trigger" href="category-horizontal-filter2.html#">Price</a>
                                <div className="sort-list">
                                    <div className="collapse show" id="widget-body-2">
                                        <div className="widget-body pb-0" style={{paddingTop: "0px"}}>
                                            <form action="demo21-shop.html#" style={{marginBottom: "0px"}}>
                                                <div className="price-slider-wrapper">
                                                    <div id="price-slider"></div>
                                                </div>

                                                <div
                                                    className="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
                                                    <div className="filter-price-text">
                                                        Price:
                                                        <span id="filter-price-range"></span>
                                                    </div>

                                                    <button type="submit" className="btn btn-primary">Filter</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                        <div className="toolbox-item toolbox-sort select-custom">
                            <a className="sort-menu-trigger" href="category-horizontal-filter2.html#">Default sorting</a>
                            <ul className="sort-list">
                                <li><a href="category-horizontal-filter2.html#">Sort by popularity</a></li>
                                <li><a href="category-horizontal-filter2.html#">Sort by popularity</a></li>
                                <li><a href="category-horizontal-filter2.html#">Sort by average rating</a></li>
                                <li><a href="category-horizontal-filter2.html#">Sort by newnes</a></li>
                                <li><a href="category-horizontal-filter2.html#">Sort by price: low to high</a></li>
                                <li><a href="category-horizontal-filter2.html#">Sort by price: high to low</a></li>
                            </ul>
                        </div>
                            
                        <div className="toolbox-item toolbox-show ml-auto"></div>
                            
                    </nav>
                    </>
                    ) : (<></>)
                }

                {/* Container products */}
                {props.children}
                {/* End Container products */}

                <nav className="toolbox toolbox-pagination"></nav>
            </div>

            <div className="sidebar-overlay"></div>
            <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
                <div className="sidebar-wrapper">

                    {
                         banners.map((banner) => (
                            <BannerLeft key={banner.id} banner={banner} />
                         ))
                    }
                </div>
            </aside>
        </div>

        <div className="mb-4"></div>
        </>
        </LayoutSimple>
    )
}

export default LayoutShop;