import React, { useEffect } from 'react'
import LayoutSimple from '../../components/layouts/LayoutSimple';

const Details = ({settingLoading, user, cart}) => {
    
    useEffect(() => {
        settingLoading();
    });
  return (
    <LayoutSimple divMain={true} user={user} cart={cart}></LayoutSimple>
  )
}

export default Details