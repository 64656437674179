import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer>
            <div className="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row align-items-center">
                            
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="footer-middle">
                        <div className="row">
                            <div className="col-lg-6 mb-1">
                                <a href="demo21.html">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="PartAutos" className="logo mb-3 mb-lg-6"/>
                                </a>

                                <div className="row no-gutters m-0">
                                    <div className="col-md-4 mb-2">
                                        <div className="contact-widget phone">
                                            <h4 className="widget-title">Llamanos:</h4>
                                            <a href="tel:18092395596">809-239-5596</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <div className="contact-widget email">
                                            <h4 className="widget-title">Email:</h4>
                                            <a href="mailto:contacto@partautos.com">contacto@partautos.com</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <div className="contact-widget follow">
                                            <h4 className="widget-title ls-n-10">follow us</h4>
                                            <div className="social-icons">
                                                <a title="Instragram" className="social-icon" href="https://www.facebook.com/PartAutos-107552740877724" target="_blank">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/icon-facebook.png"} />                     
                                                </a>
                                                <a title="Instragram" className="social-icon" href="https://www.instagram.com/part_autos" target="_blank">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/icon-instagram.png"} />                     
                                                </a>
                                                {/* <a href="https://www.facebook.com/PartAutos-107552740877724" className="social-icon social-facebook icon-facebook" target="_blank"></a>
                                                <a href="https://www.instagram.com/part_autos" className="social-icon social-instegram icon-instegram" target="_blank"></a> */}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="widget">
                                    <h4 className="widget-title">Nosotos</h4>

                                    <ul className="links">
                                        <li><a href="demo21-about.html">Acerca de nosotros</a></li>
                                        <li><Link to="/contact">Contacto</Link></li>
                                        <li><a href="demo21.html#">Politicas</a></li>
                                        <li><a href="demo21.html#">Terminos &amp; Condiciones</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom d-sm-flex align-items-center">
                        <div className="footer-left">
                            <span className="footer-copyright">© Porto eCommerce. 2021. All Rights Reserved</span>
                        </div>

                        <div className="footer-right ml-auto mt-1 mt-sm-0">
                            <img src={process.env.PUBLIC_URL + "/assets/images/demoes/demo21/payment-icon.png"} alt="payment"/>
                        </div>
                    </div>
                </div>
                
            </div>
        </footer>
  )
}
