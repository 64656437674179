import React from 'react'
import { Link } from 'react-router-dom'
import {Endpoint} from '../../server/Endpoint';

const CardProductShop = ({product}) => {
  return (
    <div className="col-6 col-sm-4 col-lg-3">
        <div className="product-default inner-quickview inner-icon">
            <figure>
                <Link to={"/product/" + product.productoSucursalId}>
                    <img src={Endpoint.storage + "/image/" + product.imagen} width="217" height="217" alt="product" />
                    <img src={Endpoint.storage + "/image/" + product.imagen} width="217" height="217" alt="product" />
                </Link>
            </figure>
            <div className="product-details">
                <div className="category-wrap">
                    <div className="category-list">
                        <p>{product.codigo}</p>
                    </div>
                </div>
                <h3 className="product-title">
                    <Link to={"/product/" + product.productoSucursalId}>{product.producto}</Link>
                </h3>
                <div className="ratings-container">
                    <div className="product-ratings">
                        <span className="ratings" style={{width: "0%"}}></span>
                        <span className="tooltiptext tooltip-top"></span>
                    </div>
                </div>
                <div className="price-box">
                    <span className="product-price">DOP$ {(product.precioSimple !== "0.00" ? product.precioSimple : product.precioMinimo)}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CardProductShop