import React, { useEffect, useState } from 'react'
import CardCover from '../components/layouts/CardCover';
import Layout from '../components/layouts/Layout';
import CardProductSection from '../components/product/CardProductSection';
import Api from '../server/Api';
import {Endpoint} from '../server/Endpoint';

const Home = ({settingLoading, cart, user, getUserLogin}) => {

    const [products, setProducts] = useState([]);
    const [latestProduct, setLatestProduct] = useState([]);
    
    useEffect(() => {
        let api = new Api();
        (async () => {
            const productsTmp = await api.get(Endpoint.host + Endpoint.products + Endpoint.productsRecient + '?offset=0&limit=6');
            setProducts(productsTmp);
            // console.log('Home', productsTmp)
        })();
    }, []);

    useEffect(() => {
        let api = new Api();
        (async () => {
            const productsTmp = await api.get(Endpoint.host + Endpoint.products + Endpoint.productsRecient + '?offset=0&limit=6&latest=1');
            setLatestProduct(productsTmp);
        })();
    }, []);

    // Load user
    useEffect(() => {
        // console.log(user);

        if(user == null){
            // console.log('Ook', getUserLogin());
        }
    }, []);

    // Looading js
    useEffect(() => {
        settingLoading();
    });

  return (
      <Layout cart={cart} user={user}>
          <>
            <section className="intro-section">
            <div className="home-slider slide-animate owl-carousel owl-theme owl-carousel-lazy dot-inside" data-owl-options="{
            'nav': false,
            'dots': true,
            'responsive': {
                '576': {
                    'dots': false
                }
            }
            }">
                <CardCover />

            </div>

            <div className="home-slider-sidebar d-none d-sm-block">
                <div className="container">
                    <ul>
                        <li className="active">Drones</li>
                    </ul>
                </div>
            </div>
            </section>

            <section className="featured-products-section appear-animate" data-animation-name="fadeIn" data-animation-delay="200">
                <div className="container">
                    <h2 className="section-title ls-n-10 pb-3 m-b-4">Articulos destacados</h2>

                    <div className="row no-gutters">
                        {
                            products?.map((product) => (
                                <CardProductSection 
                                key={product.productoSucursalId}
                                product={product}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>

            <section>
                <div className="container">

                    <h2 className="section-title ls-n-10 pb-3 m-b-4 appear-animate" data-animation-name="fadeIn" data-animation-delay="300">Nuevos articulos</h2>

                    <div className="row no-gutters appear-animate" data-animation-name="fadeIn" data-animation-delay="300">
                        {/* <CardProductSection /> */}

                        {
                            latestProduct?.map(product => (
                                <CardProductSection 
                                key={product.productoSucursalId}
                                product={product}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>

            <div className="feature-boxes-container bg-gray m-b-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature-box feature-box-simple text-center mb-2 appear-animate" data-animation-name="fadeInRightShorter" data-animation-delay="200">
                                <div className="feature-box-icon">
                                    <i className="icon-shipping"></i>
                                </div>

                                <div className="feature-box-content p-0">
                                    <h3 className="font1 ls-n-10">Delivery</h3>
                                    <h5 className="font1 m-b-3">De 8:00 am - 6:00 pm</h5>
                                </div>
                                
                            </div>
                            
                        </div>
                        

                        <div className="col-md-4">
                            <div className="feature-box feature-box-simple text-center mb-2 appear-animate" data-animation-name="fadeInRightShorter" data-animation-delay="400">
                                <div className="feature-box-icon">
                                    <i className="icon-money"></i>
                                </div>

                                <div className="feature-box-content p-0">
                                    <h3 className="font1 ls-n-10">Garantizamos su dinero</h3>
                                    <h5 className="font1 m-b-3">Rápido &amp; Seguro</h5>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="feature-box feature-box-simple text-center mb-2 appear-animate" data-animation-name="fadeInRightShorter" data-animation-delay="600">
                                <div className="feature-box-icon">
                                    <i className="icon-support"></i>
                                </div>
                                <div className="feature-box-content p-0">
                                    <h3 className="font1 ls-n-10">Soporte En linea</h3>
                                    <h5 className="font1 m-b-3">Necesitas ayuda?</h5>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>

                </div>
            </div>

            <section className="brands-section">
                <div className="container">
                <h2 className="section-title ls-n-10 pb-3 mb-5 appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="200" data-animation-duration="400">Marcas</h2>

                <div className="brands-slider owl-carousel owl-theme images-center appear-animate" data-animation-name="fadeIn" data-animation-delay="400" data-owl-options="{
                    'margin': 30,
                    'responsive': {
                        '991': {
                            'items': 4
                        },
                        '1200': {
                            'items': 5
                        }
                    }
                }">
                    <img src="assets/images/demoes/demo21/brands/brand1.png" width="200" height="50" alt="brand"/>
                    <img src="assets/images/demoes/demo21/brands/brand2.png" width="200" height="50" alt="brand"/>
                    <img src="assets/images/demoes/demo21/brands/brand3.png" width="200" height="50" alt="brand"/>
                    <img src="assets/images/demoes/demo21/brands/brand4.png" width="200" height="50" alt="brand"/>
                    <img src="assets/images/demoes/demo21/brands/brand5.png" width="200" height="50" alt="brand"/>
                </div>

                </div>
            </section>
          </>
      </Layout>
  );

}

export default Home