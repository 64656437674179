import React, {useEffect, useState} from 'react'
import LayoutShop from '../components/layouts/LayoutShop';
import CardProductShop from '../components/product/CardProductShop';
import Api from '../server/Api';
import {Endpoint} from '../server/Endpoint';

const Shop = ({settingLoading, cart, user}) => {

    const [products, setProducts] = useState([]);
    useEffect(() => {
        let api = new Api();
        (async () => {
            const productsTmp = await api.get(Endpoint.host + Endpoint.products + Endpoint.productsRecient + '?offset=0&limit=6');
            setProducts(productsTmp);
        })();
    }, []);

    useEffect(() => {
        settingLoading();
    });
  
    return (
        <LayoutShop cart={cart} user={user}>
        <div className="row no-gutters">
            {
                products?.map((product) => (
                    <CardProductShop 
                    key={product.productoSucursalId}
                    product={product}
                    />
                ))
            }
        </div>
        </LayoutShop>
    )
}

export default Shop