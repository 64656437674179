import React from 'react'

function CardProductFlex() {
  return (
    <div className="product">
        <div className="product-details">
            <h4 className="product-title">
                <a href="demo21-product.html">Ultimate</a>
            </h4>

            <span className="cart-product-info">
                <span className="cart-product-qty">1</span> × $99.00
            </span>
        </div>
        

        <figure className="product-image-container">
            <a href="demo21-product.html" className="product-image">
                <img src="assets/images/products/product-1.jpg" alt="product" width="80" height="80"/>
            </a>

            <a href="demo21.html#" className="btn-remove" title="Remove Product"><span>×</span></a>
        </figure>
    </div>
  )
}

export default CardProductFlex