import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const FormHeaderSearch = () => {

  const [searchParams] = useSearchParams();
  let filer = {
    k: searchParams.get("k") || '',
    c: searchParams.get("c") || 0
  }
  let [search, setSearch] = useState(filer);
  const changeState = e => {
    setSearch(
      {
        ...search,
        [e.target.name] : e.target.value
      }
    )
  }

  return (
    <form action="/s" method="get">
        <div className="header-search-wrapper">
            <input type="search" className="form-control" name="k" id="q1" placeholder="Encontrar articulo" onChange={changeState} value={search.k}/>
            <div className="select-custom">
                <select id="sel-cat" name="c" onChange={changeState} value={search.c}>
                    <option value="0">Todos</option>
                    <option value="1">Otros</option>
                    <option value="2">Pasando</option>
                    {/* Categories database */}
                </select>
            </div>
            <button className="btn icon-search-3 bg-dark text-white p-0" title="search" type="submit"></button>
        </div>
    </form>
  )
}

export default FormHeaderSearch