import React from 'react'
import { Link } from 'react-router-dom'

function NavBasic() {
  return (
    <div className="sticky-navbar">
        <div className="sticky-info">
            <Link to="">
                <i className="icon-home"></i>Inicio
            </Link>
        </div>
        <div className="sticky-info">
            <Link to="tienda" className="">
                <i className="icon-bars"></i>Tienda
            </Link>
        </div>
    </div>
  )
}

export default NavBasic